//export const HOST = 'https://apimv.ticservice.org:17100/';
export const HOST = process.env.REACT_APP_API_URL;
export const VERSION = 'api/v1/';

const APP_URL = {
    // AUTH
    LOGIN: `${HOST}${VERSION}auth/login/`,
    //HOME
    SELECTDB: `${HOST}${VERSION}admin/request/selectdb`,
    VERIRYNUMBEREXCEL: `${HOST}${VERSION}admin/request/verifyNumbersExcel`,
    VERIRYNUMBERASESOREXCEL: `${HOST}${VERSION}admin/request/verifyNumbersAsesorExcel`,
    LOADNUMBERSCREATEEXCEL: `${HOST}${VERSION}admin/request/loadNumbersCreateExcel`,
    LOADNUMBERSTRASHEXCEL: `${HOST}${VERSION}admin/request/loadNumbersTrashExcel`,
    LOADNUMBERSTIPIFYCALLS: `${HOST}${VERSION}admin/request/loadNumbersTipifyCalls`,
    LISTNUMBERTIPIFYCALLS: `${HOST}${VERSION}admin/request/listNumbersTipifyCalls`,
    FILTERNUMBERTIPIFYCALLS: `${HOST}${VERSION}admin/request/filterNumbersTipifyCalls`,
    LOADNUMBERS: `${HOST}${VERSION}admin/request/loadNumbers`,
    LOADNUMBERSVII: `${HOST}${VERSION}admin/request/loadNumbersvii`,
    DOWNLOADEXCELNUMBER: `${HOST}${VERSION}admin/request/downloadExcelNumber`,
    DOWNLOADEXCELNUMBERTIPIFYCALLS: `${HOST}${VERSION}admin/request/downloadNumbersTipifyCalls`,
    VIEWLISTLOG: `${HOST}${VERSION}admin/request/viewListLog`,
    LISTTRASHNUMBER: `${HOST}${VERSION}admin/request/listTrashNumber`,

    LISTUSERS: `${HOST}${VERSION}admin/users/list`,
    CREATEUSERS: `${HOST}${VERSION}admin/users/create`,
    TOTALSALES: `${HOST}${VERSION}admin/request/totalSales`,
    TOTALSALESRENEW: `${HOST}${VERSION}admin/request/totalSalesRenew`,
    TOTALSALESAGENTS: `${HOST}${VERSION}admin/request/totalSalesAgents`,


}

export default APP_URL