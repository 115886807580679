import React, { createContext, useContext, useState, useEffect } from 'react'
import API from '../providers/api'
const user = JSON.parse(sessionStorage.getItem('user'))
export const DataNavbarContext = createContext()

export const DataNavbarProvider = ({ children }) => {

    const [totalTipifyIngles, setTotalTipifyIngles] = useState(0)
    const [totalTipifyMayor65, setTotalTipifyMayor65] = useState(0)
    const [totalTipifySeguroTrabajo, setTotalTipifySeguroTrabajo] = useState(0)
    const [totalTipifyMedicare, setTotalTipifyMedicare] = useState(0)
    const [totalTipifyMedicate, setTotalTipifyMedicate] = useState(0)
    const [totalTipifyColgo, setTotalTipifyColgo] = useState(0)
    const [totalTipifyNoContesto, setTotalTipifyNoContesto] = useState(0)
    const [totalTipifyBuzon, setTotalTipifyBuzon] = useState(0)
    const [totalTipifyCuentaConAgente, setTotalTipifyCuentaConAgente] = useState(0)
    const [totalTipifyNoEstaInteresado, setTotalTipifyNoEstaInteresado] = useState(0)

    const [dataListTipifyDT, setDataListTipifyDT] = useState([])

    const getTotalNavbarLeads = async (url) => {
        try {
            let name = '', number = ''
            switch (url) {
                case '/tipify-calls-1':
                    name = 'Habla inglés'
                    number = 1
                    break;
                case '/tipify-calls-2':
                    name = 'Mayor de 65 años'
                    number = 2
                    break;
                case '/tipify-calls-3':
                    name = 'Cuenta con seguro de trabajo'
                    number = 3
                    break;
                case '/tipify-calls-4':
                    name = 'Medicare'
                    number = 4
                    break;
                case '/tipify-calls-5':
                    name = 'Medicate'
                    number = 5
                    break;
                case '/tipify-calls-6':
                    name = 'Colgó'
                    number = 6
                    break;
                case '/tipify-calls-7':
                    name = 'No contesto'
                    number = 7
                    break;
                case '/tipify-calls-8':
                    name = 'Buzón'
                    number = 8
                    break;
                case '/tipify-calls-9':
                    name = 'Cuenta con agente'
                    number = 9
                    break;
                case '/tipify-calls-10':
                    name = 'No está interesado'
                    number = 10
                    break;
            }
            API.listNumbersTipifyCalls(number,name)
                .then(res => res.json())
                .then(json => {
                    if(json.success == true){
                        switch (url) {
                            case '/tipify-calls-1':
                                setTotalTipifyIngles(json.data.length > 0 ? json.data.reduce((acumulador, actual) => acumulador + parseInt(actual.total), 0) : 0)
                                break;
                            case '/tipify-calls-2':
                                setTotalTipifyMayor65(json.data.length > 0 ? json.data.reduce((acumulador, actual) => acumulador + parseInt(actual.total), 0) : 0)
                                break;
                            case '/tipify-calls-3':
                                setTotalTipifySeguroTrabajo(json.data.length > 0 ? json.data.reduce((acumulador, actual) => acumulador + parseInt(actual.total), 0) : 0)
                                break;
                            case '/tipify-calls-4':
                                setTotalTipifyMedicare(json.data.length > 0 ? json.data.reduce((acumulador, actual) => acumulador + parseInt(actual.total), 0) : 0)
                                break;
                            case '/tipify-calls-5':
                                setTotalTipifyMedicate(json.data.length > 0 ? json.data.reduce((acumulador, actual) => acumulador + parseInt(actual.total), 0) : 0)
                                break;
                            case '/tipify-calls-6':
                                setTotalTipifyColgo(json.data.length > 0 ? json.data.reduce((acumulador, actual) => acumulador + parseInt(actual.total), 0) : 0)
                                break;
                            case '/tipify-calls-7':
                                setTotalTipifyNoContesto(json.data.length > 0 ? json.data.reduce((acumulador, actual) => acumulador + parseInt(actual.total), 0) : 0)
                                break;
                            case '/tipify-calls-8':
                                setTotalTipifyBuzon(json.data.length > 0 ? json.data.reduce((acumulador, actual) => acumulador + parseInt(actual.total), 0) : 0)
                                break;
                            case '/tipify-calls-9':
                                setTotalTipifyCuentaConAgente(json.data.length > 0 ? json.data.reduce((acumulador, actual) => acumulador + parseInt(actual.total), 0) : 0)
                                break;
                            case '/tipify-calls-10':
                                setTotalTipifyNoEstaInteresado(json.data.length > 0 ? json.data.reduce((acumulador, actual) => acumulador + parseInt(actual.total), 0) : 0)
                                break;
                        }
                    }
                })
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        if(user != null){
            getTotalNavbarLeads('/tipify-calls-1')
            getTotalNavbarLeads('/tipify-calls-2')
            getTotalNavbarLeads('/tipify-calls-3')
            getTotalNavbarLeads('/tipify-calls-4')
            getTotalNavbarLeads('/tipify-calls-5')
            getTotalNavbarLeads('/tipify-calls-6')
            getTotalNavbarLeads('/tipify-calls-7')
            getTotalNavbarLeads('/tipify-calls-8')
            getTotalNavbarLeads('/tipify-calls-9')
            getTotalNavbarLeads('/tipify-calls-10')
        }
    }, [dataListTipifyDT])

    return (
        <DataNavbarContext.Provider value={{
            totalTipifyIngles, setTotalTipifyIngles, totalTipifyMayor65, setTotalTipifyMayor65, totalTipifySeguroTrabajo, setTotalTipifySeguroTrabajo,
            totalTipifyMedicare, setTotalTipifyMedicare, totalTipifyMedicate, setTotalTipifyMedicate, totalTipifyColgo, setTotalTipifyColgo, totalTipifyNoContesto, setTotalTipifyNoContesto,
            totalTipifyBuzon, setTotalTipifyBuzon, totalTipifyCuentaConAgente, setTotalTipifyCuentaConAgente, totalTipifyNoEstaInteresado, setTotalTipifyNoEstaInteresado,
            dataListTipifyDT, setDataListTipifyDT }}>
            {children}
        </DataNavbarContext.Provider>
    )
}

export const useDataNavbarContext = () => {
    return useContext(DataNavbarContext)
}

